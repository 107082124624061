import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

import TopBar from '../Layout/TopBar/TopBar';
import Footer from '../Layout/Footer/Footer';

import { LanguageContext } from '../../shared/ContextProvider/LocalizationContext';
import { AppLanguage } from '../../types/AppLanguage';

const StandardLayout = () => {
  const [language, setLanguage] = useState<AppLanguage>('Swedish');
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <TopBar setLanguage={setLanguage} />
      <Container maxWidth={false} sx={{ pt: '32px', pr: { lg: '80px' }, pl: { lg: '80px' } }}>
        <Outlet />
      </Container>
      <Footer />
    </LanguageContext.Provider>
  );
};

export default StandardLayout;

import { AppLanguage } from '../types/AppLanguage';
import texts from './localizationStrings.json';

export function getCurrentLocaleValue(language: 'English' | 'swedish') {
  switch (language.toLowerCase()) {
    case 'english':
      return 'en-GB';
    case 'swedish':
    default:
      return 'sv-SE';
  }
}

export function getTextByKey(key?: string, language?: AppLanguage) {
  if (language == null) return 'invalid: ' + key;
  if (key == null) return 'no key';

  const text = texts[key as keyof typeof texts];
  if (!text) return 'invalid: ' + key;

  return text[language] ?? language.charAt(0) + ':' + key;
}

export function getCultureByLanguage(language: string): string {
  switch (language) {
    case 'English':
      return 'en';
    case 'Swedish':
      return 'sv';
    default:
      break;
  }
  return 'sv-SE';
}

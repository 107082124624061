import { useContext, useEffect, useState } from 'react';

import { Card, CardContent, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { StyledListContainerXl } from '../../common/Container.styles';

import { IssuerContext } from '../../shared/ContextProvider/IssuerProvider';
import DashboardController from '../../shared/Controller/DashboardController';
import { IShortfacts } from '../../types/IShortfacts';
import { useQuery } from 'react-query';
import WidgetShortFacts from './Shortfacts/ShortFacts';
import { getTextByKey } from '../../shared/LocalizationHelper';
import { useLanguage } from '../../shared/ContextProvider/useLanguage';

export default function Dashboard() {
  const issuer = useContext(IssuerContext);
  const [shortfactsData, setShortfactsData] = useState<IShortfacts[] | undefined>(undefined);
  const language = useLanguage();
  useEffect(() => {
    getShortfacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuer.issuerId]);

  const fetchShortfacts = async () => {
    const issuerResponse = await DashboardController.GetIssuerShortfacts();
    return issuerResponse.data;
  };

  const {
    isLoading: isLoadingShortfacts,
    isError: isApiError,
    refetch: getShortfacts
  } = useQuery<IShortfacts[], Error>(
    'issuerShortFacts',
    async () => {
      return await fetchShortfacts();
    },
    {
      enabled: false,
      onSuccess: res => {
        setShortfactsData(res);
      }
    }
  );
  return (
    <StyledListContainerXl>
      <Typography variant="h4" mb={4}>
        {getTextByKey('Dashboard', language)}
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} md={8}>
          <Card variant="outlined">
            <CardContent sx={{ pt: 1, pb: 1 }}>
              <Typography variant="body1" component="div">
                {getTextByKey('ShortFacts', language)}
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <WidgetShortFacts
                shortfactsData={shortfactsData}
                isApiError={isApiError}
                isLoadingShortfacts={isLoadingShortfacts}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={4}>
          <Card variant="outlined">
            <CardContent sx={{ pt: 1, pb: 1 }}>
              <Typography variant="body1" component="div">
                &nbsp;
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>&nbsp;</CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Gömmer denna för nu, vet inte hur den ska användas */}
      <Typography variant="h4" sx={{ display: 'none' }}>
        {issuer?.issuerName}
      </Typography>
    </StyledListContainerXl>
  );
}

import axios from 'axios';
import { apiInterceptor } from './ApiInterceptor';

const api = axios.create();
api.interceptors.request.use(apiInterceptor, error => Promise.reject(error));

export default class DashboardController {
  static async GetIssuerShortfacts() {
    const url = '/issuerportaldashboard/shortfacts';
    return await api.get(url);
  }
}

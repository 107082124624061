import { useEffect, useState } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import AppRoutes from './routing/AppRoutes';

import { IssuerContext } from './shared/ContextProvider/IssuerProvider';
import { IIssuer } from './types/Issuer';
import { LanguageContext } from './shared/ContextProvider/LocalizationContext';
import { AppLanguage } from './types/AppLanguage';
import './index.css';
import { useThemeContext } from './Theme/ThemeContextProvider';

function App({ children }: Readonly<{ children?: React.ReactNode }>) {
  const [selectedAdminIssuer, setSelectedAdminIssuer] = useState<IIssuer | null>(null);
  const [language, setLanguage] = useState<AppLanguage>('Swedish');
  useEffect(() => {
    if (selectedAdminIssuer) {
      sessionStorage.setItem('admin-issuerId', selectedAdminIssuer.legalEntityId);
    } else {
      sessionStorage.removeItem('admin-issuerId');
    }
  }, [selectedAdminIssuer]);

  const { theme } = useThemeContext();

  return (
    <IssuerContext.Provider
      value={{
        selectedAdminIssuer,
        setSelectedAdminIssuer,
        issuerId: selectedAdminIssuer ? selectedAdminIssuer.legalEntityId : '',
        issuerName: selectedAdminIssuer ? selectedAdminIssuer.name : '',
        organisationNumber: selectedAdminIssuer ? selectedAdminIssuer.organisationNumber : ''
      }}
    >
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppRoutes />
          {children}
        </ThemeProvider>
      </LanguageContext.Provider>
    </IssuerContext.Provider>
  );
}

export default App;

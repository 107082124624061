import { Box, Divider, Link, Stack, Toolbar, Typography } from '@mui/material';

export default function Footer() {
  return (
    <Toolbar
      sx={{
        pt: '100px',
        pr: { lg: '80px' },
        pl: { lg: '80px' },
        alignItems: 'flex-start'
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Divider />
        <Stack
          direction="row"
          gap={1}
          sx={{
            pt: '20px',
            pb: '20px',
            opacity: '.75'
          }}
        >
          <Stack>
            <Typography variant="body2">© 2023 Euroclear</Typography>
          </Stack>
          <Stack>
            <Typography variant="body2">&mdash;</Typography>
          </Stack>
          <Stack>
            <Link
              href="https://www.euroclear.com/legal/en.html"
              target="_blank"
              rel="noopener"
              underline="hover"
              variant="body2"
              color="inherit"
            >
              Disclaimer
            </Link>
          </Stack>
          <Stack>
            <Typography variant="body2">&mdash;</Typography>
          </Stack>
          <Stack>
            <Link
              href="https://www.euroclear.com/legal/en/privacy.html"
              target="_blank"
              rel="noopener"
              underline="hover"
              variant="body2"
              color="inherit"
            >
              Privacy and cookie notice
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Toolbar>
  );
}

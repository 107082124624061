import { flexRender } from '@tanstack/react-table';
import { Alert, Skeleton, Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getTextByKey } from '../../../shared/LocalizationHelper';
import { useLanguage } from '../../../shared/ContextProvider/useLanguage';

export default function ShortFactsBodyTable(
  props: Readonly<{
    table: any;
    isLoading: boolean;
    isApiError: boolean;
    totalRows: number;
  }>
) {
  const language = useLanguage();

  return (
    <Table size="small" aria-label="short facts table">
      <TableHead>
        {props.table.getHeaderGroups().map((headerGroup: { id: any; headers: any[] }) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <TableCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {props.isLoading && (
          <TableRow>
            <TableCell colSpan={5}>
              <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '21px' }} />
            </TableCell>
          </TableRow>
        )}
        {props.isApiError && (
          <TableRow>
            <TableCell colSpan={5}>
              <Alert sx={{ width: '100%', maxWidth: '1500px', height: 'auto' }} severity="error">
                {getTextByKey('ErrorMessage', language)}
              </Alert>
            </TableCell>
          </TableRow>
        )}
        {props.totalRows === 0 && !props.isLoading && (
          <TableRow>
            <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
              {getTextByKey('NoDataAvailable', language)}
            </TableCell>
          </TableRow>
        )}

        {!props.isLoading &&
          !props.isApiError &&
          props.table
            .getRowModel()
            .rows.map((row: { original: any; id: any; getVisibleCells: () => any[] }) => {
              return (
                <TableRow
                  key={row.id}
                  className={`${row.getIsExpanded() ? 'table__expandedrow' : 'table__normalrow'} ${
                    row.original.isExpandable ? 'table__subrow' : ''
                  }`}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableCell
                        key={cell.id}
                        className={cell.column.columnDef.meta?.bodyClassName}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
      </TableBody>
    </Table>
  );
}

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import StandardLayout from '../components/StandardLayout/StandardLayout';
import Dashboard from '../pages/Dashboard/Dashboard';
import Contact from '../pages/Contact/Contact';
import Page404 from '../pages/Page404/Page404';
import LoadingBox from '../shared/LoadingBox';

const AppRoutes = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <StandardLayout />,
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: 'contact',
          element: <Contact />
        },
        {
          path: '*',
          element: <Page404 />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<LoadingBox />} />;
};

export default AppRoutes;

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Autocomplete, Skeleton, TextField } from '@mui/material';

import { formatOrgNumber } from '../../shared/AdminHelper';
import IssuerManagementController from '../../shared/Controller/IssuerManagementController';
import { IIssuer } from '../../types/Issuer';
import { useIssuerContext } from '../../shared/ContextProvider/useIssuerContext';
import { getTextByKey } from '../../shared/LocalizationHelper';
import { useLanguage } from '../../shared/ContextProvider/useLanguage';
export default function SelectIssuer() {
  const { selectedAdminIssuer, setSelectedAdminIssuer } = useIssuerContext();
  const [issuers, setIssuers] = useState<IIssuer[] | undefined>(undefined);
  const language = useLanguage();
  useEffect(() => {
    getAllIssuers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchIssuers = async () => {
    const issuerResponse = await IssuerManagementController.GetAllIssuers();
    return issuerResponse.data;
  };

  const {
    isLoading: isLoadingIssuers,
    isError: isApiError,
    refetch: getAllIssuers
  } = useQuery<IIssuer[], Error>(
    'issuers',
    async () => {
      return await fetchIssuers();
    },
    {
      enabled: false,
      onSuccess: res => {
        const defaultMockIssuer = res.find(
          (issuer: { legalEntityId: string }) => issuer.legalEntityId === '6551234123X'
        );
        if (defaultMockIssuer) {
          setSelectedAdminIssuer(defaultMockIssuer);
        }
        setIssuers(res);
      }
    }
  );

  return (
    <>
      {issuers === undefined && !isApiError && isLoadingIssuers && (
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ width: '100%', maxWidth: '720px', height: '40px' }}
        />
      )}
      {issuers !== undefined && !isApiError && (
        <Autocomplete
          value={selectedAdminIssuer}
          onChange={(_event: any, newValue: any) => setSelectedAdminIssuer(newValue)}
          options={issuers}
          size="small"
          isOptionEqualToValue={(
            option: { organisationNumber: any },
            value: { organisationNumber: any }
          ) => option.organisationNumber === value.organisationNumber}
          sx={{ width: '100%', maxWidth: '720px' }}
          getOptionLabel={(option: { name: any; organisationNumber: string }) =>
            `${option.name} ${
              formatOrgNumber(option.organisationNumber)
                ? ', ' + formatOrgNumber(option.organisationNumber)
                : ''
            }`
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              sx={{ backgroundColor: `background.paper`, borderRadius: '4px' }}
              placeholder={getTextByKey('Issuer', language)}
            />
          )}
          filterOptions={(options: any[], state: { inputValue: string }) =>
            options.filter(
              opt =>
                opt.organisationNumber
                  .toLowerCase()
                  .includes(state.inputValue.replace('-', '').toLowerCase()) ||
                opt.name.toLowerCase().includes(state.inputValue.toLowerCase())
            )
          }
        />
      )}
    </>
  );
}

import { useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { StyledListContainerXl } from '../../common/Container.styles';

export default function Contact() {
  useEffect(() => {
    document.title = 'Contact | BOSS';
  }, []);

  return (
    <StyledListContainerXl>
      <Typography variant="h3">Contact1</Typography>
      <Typography variant="h3">Contact2</Typography>
      <Typography variant="h3">Contact3</Typography>
      <Typography variant="h3">Contact4</Typography>
    </StyledListContainerXl>
  );
}

const Colors = {
  colorwhite: '#fff',
  ecBrandGrey: '#58595b',
  ecGrey2: '#ccc',
  ecGrey3: '#efefef',
  ecTeal: '#00b5ad',
  ecTealHeaderBg: '#daf4f3',
  ecTealHeaderSubBg: '#f2fbfb',
  ecTealTextLines: '#007e79',
  ecTableHeaderBg: '#e4f2f2',
  dmGrey2: '#666',
  dmGrey3: '#444',
  dmTeal: '#007e79',
  dmTealheaderBg: '#1c1c1c',
  dmTealHeaderSubBg: '#373737',
  dmTableHeaderBg: '#00504c',
  ecRed: '#d0202e'
  // ecTealLight: '#ccf0ee',
  // ecTealLightBg: '#e4f2f2',
  // ecTealDark: '#007e79',
  // ecOrange: '#ff9933',
  // ecYellow: '#ffc526',
  // ecBlue: '#148fcc',
  // ecBlueLight: '#daf3ff',
  // ecBlack: '#333333',
  // ecPaperBorder: '#d9d9d9',
  // ecPaperBorderDm: '#666',
  // ecInputBorder: '#bcbcbc',
  // darkTeal: '#33c4be',
  // darkOrange: '#faa159',
  // ecGrey1: '#767676',
  // ecGrey4: '#f7f7f7',
  // grey999: '#999',
  // darkColor: '#00968f',
  // colorwhite: '#fff',
  // darkBlue: '#115b74',
  // softPink: '#e85F94',
  // softOrange: '#f59f48',
  // softGreen: '#6cd056',
  // black: '#000000'
};

export default Colors;

import axios from 'axios';
import { apiInterceptor } from './ApiInterceptor';

const api = axios.create();

api.interceptors.request.use(apiInterceptor, error => Promise.reject(error));

export default class IdentityController {
  static async GetLoggedInUser() {
    return await api.get(`/identity/user`);
  }
}

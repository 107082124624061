import axios from 'axios';
import { apiInterceptor } from './ApiInterceptor';

const api = axios.create();
api.interceptors.request.use(apiInterceptor, error => Promise.reject(error));

export default class IssuerManagementController {
  static async GetAllIssuers() {
    const url = '/issuermanagement/dipissuers';
    return await api.get(url);
  }
}

import { AxiosRequestConfig } from 'axios';

export const apiInterceptor = (config: AxiosRequestConfig) => {
  const issuerId = sessionStorage.getItem('admin-issuerId');
  //@ts-expect-error next-row
  config.headers.Authorization = `Bearer ${import.meta.env.VITE_TOKEN}`;
  if (issuerId) {
    //@ts-expect-error next-row
    config.headers['anz-issuer-id'] = issuerId;
  }
  config.baseURL = `${import.meta.env.VITE_API_URL}/api`;
  return config;
};

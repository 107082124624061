import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

export default function Page404() {
  useEffect(() => {
    document.title = '404 | BOSS';
  }, []);

  return (
    <Container component="section" maxWidth="md">
      <Typography variant="h3">Sorry, this page can&apos;t be found</Typography>
      <Typography variant="h4">What could have happened</Typography>
      <Typography variant="body1" gutterBottom>
        You could have used an invalid link. Please go to the Euroclear Sweden homepage to find what
        you are looking for.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you entered the URL of this page manually, please check the spelling and try again.
      </Typography>
      <Typography variant="h4" sx={{ pt: '12px' }}>
        Still stuck?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please email us at XXXXXXXX@euroclear.XXXX
      </Typography>
    </Container>
  );
}

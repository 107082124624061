import { Box, List, ListItem, ListItemText } from '@mui/material';

export default function TopBar() {
  return (
    <Box
      sx={{
        display: 'flex',
        pr: { xs: 0, md: 8 },
        alignItems: 'flex-start'
      }}
    >
      <Box
        component="img"
        src="/images/euroclear-logo.svg"
        alt="Euroclear Sweden"
        sx={{
          display: 'inline-flex',
          width: { xs: 48, sm: 58 },
          position: 'relative',
          top: '-6px'
        }}
      />
      <Box sx={{ display: 'inline-flex' }}>
        <List sx={{ p: { xs: '8px 0 0 8px', sm: '16px 0 0 8px' } }}>
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary="Euroclear Sweden"
              secondary="Issuer Portal"
              primaryTypographyProps={{
                fontSize: { xs: 12, sm: 16 },
                lineHeight: { xs: '1.5', sm: '1' }
              }}
              secondaryTypographyProps={{
                fontSize: { xs: 9, sm: 12 },
                lineHeight: '1',
                color: 'inherit'
              }}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

import { useContext } from 'react';
import { IssuerContext } from './IssuerProvider';

export function useIssuerContext() {
  const ctx = useContext(IssuerContext);
  if (ctx === null) {
    throw new Error('useIssuer must be used within IssuerContextProvider');
  }
  return ctx;
}

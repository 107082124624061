import React, { useMemo } from 'react';

import {
  ExpandedState,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';

import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from './TablePaginationActions';

import { ShortFactsTableDefinition } from './ShortFactsTableDefinition';
import ShortFactsBodyTable from './ShortFactsBodyTable';
import { getCurrentLocaleValue, getTextByKey } from '../../../shared/LocalizationHelper';
import { useLanguage } from '../../../shared/ContextProvider/useLanguage';

export default function WidgetShortFacts(
  props: Readonly<{ shortfactsData: any; isApiError: any; isLoadingShortfacts: any }>
) {
  const language = useLanguage();
  const locale = getCurrentLocaleValue(language);
  const columns = useMemo(() => ShortFactsTableDefinition(language, locale), [locale, language]);

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data: props.shortfactsData ? props.shortfactsData.shortfactsData : [],
    columns,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getSubRows: (row: { expandedRow: any }) => row.expandedRow,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    paginateExpandedRows: false,
    initialState: {
      pagination: {
        pageSize: 5
      }
    }
  });

  const { pageSize, pageIndex } = table.getState().pagination;

  return (
    <>
      <ShortFactsBodyTable
        table={table}
        isLoading={props.isLoadingShortfacts}
        isApiError={props.isApiError}
        totalRows={props.shortfactsData ? props.shortfactsData.totalRows : 0}
      />

      <TablePagination
        labelRowsPerPage={getTextByKey('RowsPerPage', language)}
        rowsPerPageOptions={[
          5,
          10,
          25,
          {
            label: getTextByKey('ShowAll', language),
            value: props.shortfactsData ? props.shortfactsData.totalRows + 1 : 0
          }
        ]}
        component="div"
        count={table.getFilteredRowModel().rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        onPageChange={(_: any, page: any) => {
          table.setPageIndex(page);
        }}
        onRowsPerPageChange={(e: { target: { value: any } }) => {
          const size = e.target.value ? Number(e.target.value) : 10;
          table.setPageSize(size);
        }}
        ActionsComponent={TablePaginationActions}
        sx={{
          '.MuiToolbar-root': {
            minHeight: 0
          },
          '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
            marginBlockStart: '10px',
            marginBlockEnd: '10px'
          }
        }}
      />
    </>
  );
}

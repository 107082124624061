import * as React from 'react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Box,
  AppBar,
  Button,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Toolbar,
  Typography
} from '@mui/material';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import EuroclearLogo from './EuroclearLogo';
import SelectIssuer from '../../Admin/SelectIssuer';
import { getTextByKey } from '../../../shared/LocalizationHelper';
import { useLanguage } from '../../../shared/ContextProvider/useLanguage';
import { IUser } from '../../../types/IUser';
import { useQuery } from 'react-query';
import { formatOrgNumber } from '../../../shared/AdminHelper';
import IdentityController from '../../../shared/Controller/IdentityController';
import NightModeToggle from '../../../Theme/NightModeToggle';

export default function TopBar(props: any) {
  const language = useLanguage();
  const { setLanguage } = props;
  const [loggedinUser, setloggedinUser] = React.useState<IUser | undefined>(undefined);
  // MENU POPOVER - PRODUCTS
  const [anchorMenuProducts, setAnchorMenuProducts] = React.useState<HTMLButtonElement | null>(
    null
  );
  const openMenuProducts = Boolean(anchorMenuProducts);
  const handleClickMenuProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuProducts(event.currentTarget);
  };
  const fetchUserInfo = async () => {
    const userResponse = await IdentityController.GetLoggedInUser();
    return userResponse.data;
  };

  const { refetch: getUserInfo } = useQuery<IUser, Error>(
    'userinfo',
    async () => {
      return await fetchUserInfo();
    },
    {
      enabled: false,
      onSuccess: res => {
        setloggedinUser(res);
      }
    }
  );
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseMenuProducts = () => {
    setAnchorMenuProducts(null);
  };

  // MENU POPOVER - HELP & CONTACTS
  const [anchorMenuHelp, setAnchorMenuHelp] = React.useState<HTMLButtonElement | null>(null);
  const openMenuHelp = Boolean(anchorMenuHelp);
  const handleClickMenuHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuHelp(event.currentTarget);
  };
  const handleCloseMenuHelp = () => {
    setAnchorMenuHelp(null);
  };

  // MENU POPOVER - SETTINGS, TABLET/DESKTOP
  const [anchorMenuSettings, setAnchorMenuSettings] = React.useState<HTMLButtonElement | null>(
    null
  );
  const openMenuSettings = Boolean(anchorMenuSettings);
  const handleClickMenuSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuSettings(event.currentTarget);
  };
  const handleCloseMenuSettings = () => {
    setAnchorMenuSettings(null);
  };

  // MENU COLLAPSE/OPEN -PRODUCTS, MOBILE
  const [openMenuMobileProducts, setOpenMenuMobileProducts] = React.useState(true);
  const handleClickMenuMobileProducts = () => {
    setOpenMenuMobileProducts(!openMenuMobileProducts);
  };

  // MENU COLLAPSE/OPEN -HELP & CONTACTS, MOBILE
  const [openMenuMobileHelp, setOpenMenuMobileHelp] = React.useState(true);
  const handleClickMenuMobileHelp = () => {
    setOpenMenuMobileHelp(!openMenuMobileHelp);
  };

  // DRAWER - MENU, MOBILE
  const [mobileOpenMenu, setMobileOpenMenu] = React.useState(false);
  const handleDrawerToggleMenu = () => {
    setMobileOpenMenu((prevState: any) => !prevState);
  };

  // DRAWER - SETTINGS, MOBILE
  const [mobileOpenSettings, setMobileOpenSettings] = useState(false);
  const handleDrawerToggleSettings = () => {
    setMobileOpenSettings((prevState: any) => !prevState);
  };
  const onChangeRadioButtonHeaderSettings = (event: { target: { value: any } }) => {
    setLanguage(event.target.value);
  };
  return (
    <>
      <AppBar position="fixed" elevation={0} variant="header">
        <Toolbar
          sx={{
            pr: { lg: '80px' },
            pl: { lg: '80px' }
          }}
        >
          {/* HAMBURGER */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggleMenu}
            sx={{ display: { xs: 'inline-flex', md: 'none' }, mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* EUROCLEAR LOGO */}
          <EuroclearLogo />

          {/* MENU LINK - Dashboard */}
          <Button
            component={NavLink}
            to="/"
            color="inherit"
            id="btn-dashboard"
            variant="menuBtn"
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          >
            {getTextByKey('Dashboard', language)}
          </Button>

          {/* MENU LINK - PRODUCTS */}
          <Button
            color="inherit"
            id="btn-products"
            aria-controls={openMenuProducts ? 'menu-products' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenuProducts ? 'true' : undefined}
            onClick={handleClickMenuProducts}
            endIcon={openMenuProducts ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            variant="menuBtn"
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          >
            {getTextByKey('Products', language)}
          </Button>
          <Menu
            id="menu-products"
            anchorEl={anchorMenuProducts}
            open={openMenuProducts}
            onClose={handleCloseMenuProducts}
            MenuListProps={{ 'aria-labelledby': 'btn-products' }}
            disableScrollLock={true}
            sx={{ '.MuiPaper-root': { minWidth: '240px' } }}
          >
            <MenuItem onClick={handleCloseMenuProducts}>Vantage</MenuItem>
            <MenuItem onClick={handleCloseMenuProducts}>B.O.S.S.</MenuItem>
          </Menu>

          {/* MENU LINK - HELP & CONTACTS */}
          <Button
            color="inherit"
            id="btn-help"
            aria-controls={openMenuHelp ? 'menu-help' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenuHelp ? 'true' : undefined}
            onClick={handleClickMenuHelp}
            endIcon={openMenuHelp ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            variant="menuBtn"
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          >
            {getTextByKey('HelpContact', language)}
          </Button>
          <Menu
            id="menu-help"
            anchorEl={anchorMenuHelp}
            open={openMenuHelp}
            onClose={handleCloseMenuHelp}
            MenuListProps={{ 'aria-labelledby': 'btn-help' }}
            disableScrollLock={true}
            sx={{ '.MuiPaper-root': { minWidth: '240px' } }}
          >
            <MenuItem onClick={handleCloseMenuHelp}>{getTextByKey('Help', language)}</MenuItem>
            <MenuItem onClick={handleCloseMenuHelp}>{getTextByKey('Contact', language)}</MenuItem>
          </Menu>

          <Box sx={{ flexGrow: 1 }}></Box>

          {/* SETTINGS, MOBILE */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggleSettings}
            sx={{ display: { xs: 'inline-flex', md: 'none' } }}
          >
            <SettingsOutlinedIcon />
          </IconButton>

          {/* SETTINGS, TABLET/DESKTOP */}
          <IconButton
            id="btn-settings"
            size="large"
            edge="start"
            color="inherit"
            aria-label="settings"
            aria-controls={openMenuSettings ? 'menu-settings' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenuSettings ? 'true' : undefined}
            onClick={handleClickMenuSettings}
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          >
            <SettingsOutlinedIcon />
          </IconButton>
          <Menu
            id="menu-settings"
            anchorEl={anchorMenuSettings}
            open={openMenuSettings}
            onClose={handleCloseMenuSettings}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            MenuListProps={{ 'aria-labelledby': 'btn-settings' }}
            disableScrollLock={true}
            sx={{ '.MuiPaper-root': { minWidth: '240px', pb: '16px' } }}
          >
            <Box sx={{ p: '16px 0 16px 16px' }}>
              <FormControl component="fieldset" className="form-radiobutton">
                <FormLabel id="language-radio-buttons">
                  {getTextByKey('Language', language)}
                </FormLabel>
                <RadioGroup
                  aria-labelledby="language-radio-buttons"
                  aria-label="language"
                  name="language"
                  value={language}
                  onChange={onChangeRadioButtonHeaderSettings}
                >
                  <FormControlLabel
                    id="topbar__radio__lang__swe"
                    value="Swedish"
                    control={<Radio />}
                    label={getTextByKey('Swedish', language)}
                  />
                  <FormControlLabel
                    id="topbar__radio__lang__eng"
                    value="English"
                    control={<Radio />}
                    label={getTextByKey('English', language)}
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <NightModeToggle />

            <MenuItem onClick={handleCloseMenuSettings}>
              <ListItemIcon>
                <ManageAccountsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={getTextByKey('MyAccount', language)} />
            </MenuItem>
            <MenuItem onClick={handleCloseMenuSettings}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={getTextByKey('LogOut', language)} />
            </MenuItem>
          </Menu>
          <Button color="inherit" sx={{ display: 'none', ml: 2, textTransform: 'none' }}>
            Login
          </Button>
        </Toolbar>

        <Divider />

        <Toolbar variant="dense" sx={{ pr: { lg: '80px' }, pl: { lg: '80px' } }}>
          <Box sx={{ flexGrow: 1 }}>
            {loggedinUser && loggedinUser.role === 'Admin' && <SelectIssuer />}
            {loggedinUser && loggedinUser.role === 'Client' && (
              <Typography variant="h6">
                {`${loggedinUser.issuer}, ${formatOrgNumber(
                  loggedinUser.issuerOrganisationNumber
                )}`}
              </Typography>
            )}
          </Box>
        </Toolbar>

        <Divider />
      </AppBar>

      <Toolbar />
      <Toolbar variant="dense" />

      {/* MOBILE MENU */}
      <Drawer
        variant="temporary"
        open={mobileOpenMenu}
        onClose={handleDrawerToggleMenu}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '320px' }
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleDrawerToggleMenu}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <EuroclearLogo />
        </Toolbar>

        <Divider />

        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="mobil-menu-list-products"
        >
          <ListItemButton component={NavLink} to="/" className="mobile-menu-list-button">
            <ListItemText primary={getTextByKey('Dashboard', language)} />
          </ListItemButton>
          <ListItemButton onClick={handleClickMenuMobileProducts}>
            <ListItemText primary={getTextByKey('Products', language)} />
            {openMenuMobileProducts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={openMenuMobileProducts} timeout="auto" unmountOnExit>
            <List disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="Vantage" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary="B.O.S.S." />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClickMenuMobileHelp}>
            <ListItemText primary={getTextByKey('HelpContact', language)} />
            {openMenuMobileHelp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={openMenuMobileHelp} timeout="auto" unmountOnExit>
            <List disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={getTextByKey('Help', language)} />
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={getTextByKey('Contact', language)} />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>

      {/* MOBILE SETTINGS */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpenSettings}
        onClose={handleDrawerToggleSettings}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '250px', pt: '16px' }
        }}
      >
        <Box sx={{ p: '16px 0 16px 16px' }}>
          <FormControl>
            <FormLabel id="mobile-language-radio-buttons">
              {getTextByKey('Language', language)}
            </FormLabel>
            <RadioGroup
              aria-labelledby="mobile-language-radio-buttons"
              defaultValue="swedish"
              name="radio-buttons-group"
              value={language}
              onChange={onChangeRadioButtonHeaderSettings}
            >
              <FormControlLabel
                id="topbar__radio__lang__swe"
                value="Swedish"
                control={<Radio />}
                label={getTextByKey('Swedish', language)}
              />
              <FormControlLabel
                id="topbar__radio__lang__eng"
                value="English"
                control={<Radio />}
                label={getTextByKey('English', language)}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <List component="nav" aria-labelledby="mobil-menu-list-settings">
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <ManageAccountsOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={getTextByKey('MyAccount', language)} />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={getTextByKey('LogOut', language)} />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}

import { useContext } from 'react';
import { LanguageContext } from './LocalizationContext';

export function useLanguage() {
  const ctx = useContext(LanguageContext);
  if (ctx === null) {
    throw new Error('useLanguage must be used within LanguageContextProvider');
  }
  return ctx.language;
}

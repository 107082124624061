import { Box, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getTextByKey } from '../../../shared/LocalizationHelper';
import { AppLanguage } from '../../../types/AppLanguage';

export const ShortFactsTableDefinition = (language: AppLanguage, locale: string) => [
  {
    accessorKey: 'expander',
    header: () => null,
    meta: {
      bodyClassName: 'table__cell__btn-expand'
    },
    cell: ({ row }: { row: any }) => (
      <Box>
        {row.getCanExpand() && (
          <IconButton
            size="small"
            sx={{ p: 0 }}
            aria-label="expand security class"
            {...{ onClick: row.getToggleExpandedHandler() }}
          >
            {row.getIsExpanded() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </Box>
    )
  },
  {
    accessorKey: 'isin',
    header: getTextByKey('Isin', language),
    cell: ({ row }: { row: any }) => (
      <Box>
        {row.getCanExpand() ? getTextByKey(row.original.isin, language) : row.original.isin}
      </Box>
    )
  },
  {
    accessorKey: 'fullName',
    header: getTextByKey('ShareClass', language)
  },
  {
    accessorKey: 'issuedQuantity',
    header: getTextByKey('Quantity', language),
    cell: ({ row }: { row: any }) => (
      <Box>{row.getCanExpand() ? '' : row.original.issuedQuantity.toLocaleString(locale)}</Box>
    )
  },
  {
    accessorKey: 'votingRight',
    header: getTextByKey('VotingRights', language),
    cell: ({ row }: { row: any }) => <Box>{row.getCanExpand() ? '' : row.original.votingRight}</Box>
  }
];

import { PaletteMode } from '@mui/material';
import Colors from './Colors';

const theme = {};

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: Colors.ecTeal
          },
          brandcolors: {
            main: Colors.ecTeal,
            iconcolor: Colors.ecBrandGrey
          },
          colorteal: {
            main: Colors.ecTeal,
            headerbg: Colors.ecTealHeaderBg,
            headersubbg: Colors.ecTealHeaderSubBg,
            textlines: Colors.ecTealTextLines
          }
        }
      : {})
  },
  components: {
    mode,
    MuiAppBar: {
      variants: [
        {
          props: { variant: 'header' },
          style: {
            backgroundColor: mode === 'light' ? Colors.ecTealHeaderBg : '',
            '.MuiToolbar-regular': {
              backgroundColor: mode === 'light' ? Colors.ecTealHeaderBg : Colors.dmTealheaderBg
            },
            '.MuiToolbar-dense': {
              backgroundColor:
                mode === 'light' ? Colors.ecTealHeaderSubBg : Colors.dmTealHeaderSubBg
            }
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'menuBtn' },
          style: {
            display: 'inline-flex',
            mr: 4,
            fontSize: '1rem',
            textTransform: 'none',
            '&.active::after': {
              content: '""',
              position: 'absolute',
              right: 0,
              bottom: '-12px',
              left: 0,
              height: '3px',
              backgroundColor: mode === 'light' ? Colors.ecTeal : Colors.dmTeal
            }
          }
        }
      ]
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.mobile-menu-list-button.active::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: '4px',
            backgroundColor: mode === 'light' ? Colors.ecTeal : Colors.dmTeal
          }
        }
      }
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'horizontalSpacer' },
          style: {
            minHeight: 32,
            border: 0
          }
        }
      ]
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          paddingBottom: '32px'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '.MuiTableHead-root': {
            '.MuiTableCell-head': {
              borderColor: mode === 'light' ? Colors.ecTealTextLines : Colors.dmTeal,
              backgroundColor: mode === 'light' ? Colors.ecTableHeaderBg : Colors.dmTableHeaderBg
            }
          },
          '.MuiTableCell-body': {
            borderBottomColor: mode === 'light' ? Colors.ecGrey2 : Colors.dmGrey2
          },
          '.table__expandedrow, .table__subrow': {
            borderLeftWidth: '1px',
            borderLeftStyle: 'solid',
            borderColor: mode === 'light' ? Colors.ecTeal : Colors.dmTeal,
            '.MuiTableCell-body': {
              borderBottomColor: mode === 'light' ? Colors.ecGrey3 : Colors.dmGrey3
            }
          },
          '.table__cell__btn-expand': {
            width: '40px',
            paddingTop: '4px',
            paddingRight: 0,
            paddingBottom: '4px'
          }
        }
      }
    }
  }
});
export default theme;

import { Box, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeContext } from './ThemeContextProvider';

import { getTextByKey } from '../shared/LocalizationHelper';
import { useLanguage } from '../shared/ContextProvider/useLanguage';

const NightModeToggle = () => {
  const { mode, toggleColorMode } = useThemeContext();
  const language = useLanguage();
  return (
    <Box
      sx={{
        p: '0 0 16px 16px'
      }}
    >
      {mode === 'dark'
        ? `${getTextByKey('DarkMode', language)}`
        : `${getTextByKey('LightMode', language)}`}
      <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
};
export default NightModeToggle;
